import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.login();
  }

  constructor(
    private oauthService: OAuthService,
    private router: Router
  ) {
    // Useful for debugging:
    // this.oauthService.events.subscribe(event => {
    //   if (event instanceof OAuthErrorEvent) {
    //     console.error(event);
    //   } else {
    //     console.warn(event);
    //   }
    // });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.
    window.addEventListener('storage', (event) => {
      console.log("----1");

      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      // console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        console.log("----2");
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events
      .subscribe(e => {
        console.log(e);
        console.log("----3");
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      });

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => {
        console.log(e);
        console.log("----4");
        this.oauthService.loadUserProfile().then((x) => {
          console.log('user info reload ', x)
          if (!x.hasOwnProperty('role')) {
            this.logout();
          }
        });
      });

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => {
        console.log(e);
        console.log("----5");
        this.navigateToLoginPage()
      }
      );


    this.oauthService.events
      .pipe(filter(e => ['code_error', 'silent_refresh_timeout'].includes(e.type)))
      .subscribe(e => {
        console.log("----6");
        this.oauthService.revokeTokenAndLogout();
      });

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public runInitialLoginSequence(): Promise<void> {
    return this.oauthService.loadDiscoveryDocument()
      .then(() => new Promise(resolve => setTimeout(() => resolve(), 1000)))
      .then(() => this.oauthService.tryLogin())
      .then(() => {
        console.log("----10");
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        } else {
          console.log("has no valid access token");
          console.log("----11");
          this.login();
        }
      })
      .then(() => {
        console.log("----12");
        this.isDoneLoadingSubject$.next(true);
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          this.router.navigateByUrl(stateUrl);
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  public login(targetUrl?: string) {

    console.log("----7");
    // to change extra query parameters
    this.oauthService.loadDiscoveryDocument();
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
    this.oauthService.getIdentityClaims();
  }

  public logout() {
    this.oauthService.logOut();
  }

  public refresh() {
    this.oauthService.silentRefresh();
  }

  public hasValidToken() {
    console.log("----8");
    return this.oauthService.hasValidIdToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }

  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }

  public get idToken() {
    return this.oauthService.getIdToken();
  }

  public get name(): string {
    if (this.claims) {
      if (this.claims.FullName) {
        return this.claims.FullName;
      } else if (this.claims.email) {
        const x = this.claims.email.indexOf('@');
        const namePlaceholder = this.claims.email.substring(0, x);
        return namePlaceholder;
      }
    }
    return '';
  }

  public get claims(): any {
    return this.oauthService.getIdentityClaims();
  }

  updateClaims(name) {
    const claims = JSON.parse(localStorage.getItem('id_token_claims_obj'));
    claims.FullName = name;
    localStorage.setItem('id_token_claims_obj', JSON.stringify(claims));
  }

  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }
}
