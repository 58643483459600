import { Component, OnInit, AfterViewInit } from '@angular/core';
import KTLayoutScrolltop from '../../../../../assets/js/layout/extended/scrolltop';
import { KTUtil } from '../../../../../assets/js/components/util';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss'],
})
export class ScrollTopComponent implements OnInit, AfterViewInit {
  // constructor() {}

  ngOnInit(): void {
            //A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time, and before any of the view or content children have been checked. It is invoked only once when the directive is instantiated.
  }

  ngAfterViewInit() {
    KTUtil.ready(() => {
      // Init Scrolltop
      KTLayoutScrolltop.init('kt_scrolltop');
    });
  }
}
